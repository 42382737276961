import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '../hooks/auth';
import { RoleType } from '../types';
import { auth, firestore } from '../utils/firebase';
import AuthContext from './auth.context';

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [id, setId] = useState<string>();
  const [roles, setRoles] = useState<RoleType[]>([]);
  const [typesenseKey, setTypesenseKey] = useState<string>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    let timeout: NodeJS.Timeout;
    if (user) {
      const ref = doc(firestore, 'identities', user.uid);
      unsubscribe = onSnapshot(ref, document => {
        if (document.exists()) {
          if (timeout) {
            clearTimeout(timeout);
          }

          setRoles(document.get('roles'));
          setId(document.get('id'));
        } else {
          timeout = setTimeout(async () => {
            await auth.signOut();
            navigate('/denied', { replace: true });
          }, 10000);
        }
      });
    } else {
      setId(undefined);
      setRoles([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [user]);

  useEffect(() => {
    if (user && id) {
    }
  }, [user, id]);

  const value = useMemo(
    () => ({
      id,
      user,
      roles,
      loading,
      error,
      typesenseKey,
    }),
    [id, user, roles, loading, error, typesenseKey]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
