import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';
import {
  DocumentSnapshot,
  getFirestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyD298rUMN61hWYzQYV-QIBRLzXNbxwIRi8',
  authDomain: 'immogpt-53cfa.firebaseapp.com',
  projectId: 'immogpt-53cfa',
  storageBucket: 'immogpt-53cfa.appspot.com',
  messagingSenderId: '921536154388',
  appId: '1:921536154388:web:bf60351bfa8a8bc959d001',
};

export const app = initializeApp(firebaseConfig);
export const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});

export const googleAuthProvider = new GoogleAuthProvider();

export const firestore = getFirestore(app);

const functions = getFunctions(app, 'europe-west6');

export const getLocationDetails = httpsCallable(functions, 'getLocationDetails');

/**
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function docToJSON<T>(doc: DocumentSnapshot | QueryDocumentSnapshot) {
  const data = doc.data();
  if (!data) return null;
  Object.keys(data).forEach(key => {
    if (data[key] instanceof Timestamp) {
      data[key] = data[key].toMillis();
    }
  });
  return {
    id: doc.id,
    ...data,
  } as any as T;
}
