import { useEffect, useRef, useState } from 'react';
import React from 'react';
import L from 'leaflet';
import {MagnifyingGlass} from 'react-loader-spinner';
import 'leaflet/dist/leaflet.css';
import { getLocationDetails } from '../../common/utils/firebase';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [locationDetailsData, setLocationDetailsData] = useState(null);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [markerLabels, setMarkerLabels] = useState({});
  const [selectedMarkersData, setSelectedMarkersData] = useState([]);


  useEffect(() => {
    if (!mapRef.current) {
      const initialMap = L.map('map').setView([47.480396, 8.208480], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
      }).addTo(initialMap);

      mapRef.current = initialMap;
    }
  }, []);

  useEffect(() => {
    if(locationDetailsData) {
      const {
        coordinates,
        schoolDistances,
        supermarketDistances,
        transitStationDistances,
        trainStationDistances
      } = locationDetailsData;

      const allLocations = [...schoolDistances, ...supermarketDistances, ...transitStationDistances, ...trainStationDistances];

      const selectedMarkersData = allLocations.filter((location: any) => selectedMarkers.includes(location.name));

      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          mapRef.current.removeLayer(layer);
        }
      });

      const { lat, lng } = coordinates;
      const customMarker = L.icon({
        iconUrl: 'marker-icon.png',
        iconSize: [32, 32], // Adjust the size as needed
        iconAnchor: [16, 32], // Position the icon anchor
      });
      const marker = L.marker([lat, lng], {icon: customMarker}).addTo(mapRef.current);      

      console.log(selectedMarkersData, marker);
      addMarkersToMap([...selectedMarkersData]);
    }
  }, [locationDetailsData, selectedMarkers]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const locationDetails = await getLocationDetails(
        {
          address,
        }
      );

      console.log('Details', locationDetails);

      setLocationDetailsData(locationDetails.data);

      if (locationDetails.data && mapRef.current) {
        const { lat, lng } = locationDetails.data.coordinates;
        const customMarker = L.icon({
          iconUrl: 'marker-icon.png',
          iconSize: [32, 32], // Adjust the size as needed
          iconAnchor: [16, 32], // Position the icon anchor
        });
        const marker = L.marker([lat, lng], {icon: customMarker}).addTo(mapRef.current);

        setMarkers([...markers, marker]);

        mapRef.current.panTo(new L.LatLng(lat, lng));
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateMarkerLabel = (name, label) => {
    setMarkerLabels((prevLabels) => ({
      ...prevLabels,
      [name]: label,
    }));
  };

  const addMarkersToMap = (markers) => {
    // Function to add markers for different types of places
    function addMarkers(locations) {
      locations.forEach((location) => {
        const { lat, lng } = location.location;
        const name = location.name
        const label = markerLabels[name] || ''; // Use the label from state

        const customIcon = L.divIcon({
          style: 'custom-marker',
          iconSize: "auto",
          html: `<b class="marker-text">${label}</b>`,
        });

        const marker = L.marker([lat, lng], { icon: customIcon }).addTo(mapRef.current);
        //marker.bindPopup(`<b>${label}:</b><br>${name}`).openPopup(); // Add label to the popup

        // Add the marker to the markers state
        setMarkers((prevMarkers) => [...prevMarkers, marker]);
      });
    }

    
    // Call the addMarkers function for each type of location
    if (markers && mapRef.current) {
      // Add schools with a custom school icon and label
      addMarkers(markers);
    }
  }

  return (
    <>
    <div>
      {loading && (
        // Div to display the loader in the center of the screen with a dark background
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}>
          <MagnifyingGlass color='black'/>
        </div>
      )}
      <h1 id="mapAncher">ImmoGPT</h1>
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Enter an address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          Search
        </button>
        <a href="#logAncher" style={{marginLeft: "10px"}}>Jump to Log</a>
      </form>
      <div style={{ display: 'block' }}>
        <div id="map" style={{ height: '800px', width: '100%' }}></div>
        <h2 id="logAncher">Log</h2><a href="#mapAncher">Jump to Map</a>
        <LocationDetails data={locationDetailsData} setSelectedMarkers={setSelectedMarkers} markers={markers} mapRef={mapRef} setMarkers={setMarkers} updateMarkerLabel={updateMarkerLabel}/>
        <button onClick={() => addMarkersToMap(selectedMarkers)}>Add Markers</button>
      </div>
      <div id="log" style={{ height: '800px', width: '45%', marginLeft: '5%'}}></div>
    </div>
    </>
  );
};

function LocationDetails({ data, setSelectedMarkers, markers, mapRef, setMarkers, updateMarkerLabel}) {
  console.log('LocationDetails', data);

  const [selectedLocations, setSelectedLocations] = useState([]);

  const handleToggleLocation = (name) => {
    console.log('Toggle location:', name);
    if (selectedLocations.includes(name)) {
      setSelectedLocations(selectedLocations.filter((item) => item !== name));
    } else {
      setSelectedLocations([...selectedLocations, name]);
    }
  };

  const handleUpdateLabel = (name, label) => {
    // Update the label for the selected location on the map
    // You can use the `markers` state to access the markers and update their labels
    const updatedMarkers = markers.map((marker) => {
      if (marker.options.name === name) {
        // Clone the marker with the updated label
        const updatedMarker = L.marker(marker.getLatLng(), {
          ...marker.options,
          name,
          label, // Set the updated label
        });

        // Remove the old marker from the map and add the updated one
        mapRef.current.removeLayer(marker);
        updatedMarker.addTo(mapRef.current);

        return updatedMarker;
      }

      return marker;
    });

    // Update the `markers` state with the updated markers
    setMarkers(updatedMarkers);
  };

  useEffect(() => {
    setSelectedMarkers(selectedLocations);
  }, [selectedLocations]);

  if (!data) {
    return <p>Enter an address</p>;
  }

  const {
    village,
    bezirk,
    kanton,
    coordinates,
    wikipediaData,
    schoolDistances,
    supermarketDistances,
    transitStationDistances,
    trainStationDistances,
  } = data;

  return (
    <div>
      <p>Ort: {village}</p>
      <p>Bezirk: {bezirk}</p>
      <p>Kanton: {kanton}</p>
      <p>Coordinates: {coordinates.lat}, {coordinates.lng}</p>

      <h2>Ort Information</h2>
      <p>Verkehr: {wikipediaData.verkehr}</p>
      <p>Bildung: {wikipediaData.bildung}</p>
      <p>Bevölkerung: {wikipediaData.bevölkerung}</p>

      <h2>Schulen</h2>
      {schoolDistances.map((school, index) => (
        <LocationItem
          key={index}
          name={school.name}
          location={school.location}
          distanceWalking={school.distanceWalking}
          distanceDriving={school.distanceDriving}
          selected={selectedLocations.includes(school.name)}
          onToggle={handleToggleLocation}
          updateMarkerLabel={updateMarkerLabel}
        />
      ))}

      <h2>Einkaufen</h2>
        {supermarketDistances.map((supermarket, index) => (
          <LocationItem
            key={index}
            name={supermarket.name}
            location={supermarket.location}
            distanceWalking={supermarket.distanceWalking}
            distanceDriving={supermarket.distanceDriving}
            selected={selectedLocations.includes(supermarket.name)}
            onToggle={handleToggleLocation}
            updateMarkerLabel={updateMarkerLabel}
          />
        ))}

      <h2>Bus Station</h2>
      {transitStationDistances.map((station, index) => (
        <LocationItem
          key={index}
          name={station.name}
          location={station.location}
          distanceWalking={station.distanceWalking}
          distanceDriving={station.distanceDriving}
          selected={selectedLocations.includes(station.name)}
          onToggle={handleToggleLocation}
          updateMarkerLabel={updateMarkerLabel}
        />
      ))}

      <h2>Bahnhof</h2>
      {trainStationDistances.map((station, index) => (
        <LocationItem
          key={index}
          name={station.name}
          location={station.location}
          distanceWalking={station.distanceWalking}
          distanceDriving={station.distanceDriving}
          selected={selectedLocations.includes(station.name)}
          onToggle={handleToggleLocation}
          updateMarkerLabel={updateMarkerLabel}
        />
      ))}
    </div>
  );
}

function LocationItem({ name, location, distanceWalking, distanceDriving, selected, onToggle, updateMarkerLabel }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCheckboxChange = () => {
    onToggle(name);
  };

  return (
    <div className="location-item">
      <label>
        <input type="checkbox" checked={selected} onChange={handleCheckboxChange} />
        {name}
      </label>
      <br />
      <label>
        Label:
        <input
  type="text"
  value={inputValue}
  onChange={(e) => {
    setInputValue(e.target.value);
    updateMarkerLabel(name, e.target.value); // Update the label in state
  }}
/>
      </label>
      <p>Location: {location.lat}, {location.lng}</p>
      <p>Distance (Walking): {distanceWalking}</p>
      <p>Distance (Driving): {distanceDriving}</p>
    </div>
  );
}

export default Home;
