import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import DeniedRoute from './pages/Denied';
import EnterRoute from './pages/Enter';
import HomeRoute from './pages/Home';
import ErrorElement from './components/ErrorElement';
import React from 'react';

const IndexRoute = {
  index: true,
  element: <Navigate to="home" />,
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorElement />,
    children: [
      IndexRoute,
      HomeRoute,
      EnterRoute,
      DeniedRoute,
    ],
  },
]);

export default router;
