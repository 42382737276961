import React from 'react';
import { ProtectedRoute } from '../../components/Routing';
import Home from './Home';

const HomeRoute = {
  path: 'home',
  element: (
    <ProtectedRoute roles={['admin']}>
      <Home />
    </ProtectedRoute>
  ),
};

export default HomeRoute;
